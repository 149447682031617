<template>
  <div class="readme-article">
    <h1 id="高级功能">
      四、高级功能
    </h1>
    <h2 id="课时奖金结算">1.课时奖金结算</h2>
    <p>管理员可根据时间结算教师课时奖金，显示统计该时间段的教师课时奖金情况。</p>
    <p>
      <img src="@/assets/img/finance/4-1.png">
    </p>
    <h2 id="课时奖金查询">2.课时奖金查询</h2>
    <p>查看课时奖金列表，包含教师信息、课时信息、奖金详细等。</p>
    <p>
      <img src="@/assets/img/finance/4-4.png">
    </p>
    <h2 id="财务流水回收站">3.财务流水回收站</h2>
    <p>查看财务流水回收记录，从财务流水中撤回的流水会在此菜单中显示。</p>
    <p>
      <img src="@/assets/img/finance/4-2.png">
    </p>
    <h2 id="导入财务资料">4.导入财务资料</h2>
    <p>下载上传资料模板并按照模板的格式填写完整数据后，选择该文件一键导入财务流水。</p>
    <p>
      <img src="@/assets/img/finance/4-2.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Finance4-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>